import {
  ArchiveIcon,
  CheckCircleIcon,
  PlusCircleIcon,
  TrashIcon,
  XCircleIcon,
} from '@heroicons/react/outline'
import React, { useState } from 'react'

import { useParams } from 'react-router'
import { useQuery } from '@apollo/client'

import Card from 'components/UI/Card'
import SkeletonBase from 'components/Skeleton/SkeletonBase'
import HeaderTitle from 'components/HeaderTitle'
import OrderDetailTable from './OrderDetailTable'
import VoucherSearchAndUpdate from 'containers/bons_de_reduction/VoucherSearchAndUpdate'
import { Button } from 'react-bootstrap'
import ActivateOrDeactivateLinesForm from './UpdateOrderLines/ActivateOrDeactivateLinesForm'
import AddNewLineForm from './UpdateOrderLines/AddNewLineForm'
import DeleteLinesForm from './UpdateOrderLines/DeleteLinesForm'
import CancelOrderForm from './CancelOrderForm'
import { CQ_GET_ORDER } from 'graphql/queries/getOrder'

interface Props {}

const OrderDetail: React.FC<Props> = () => {
  const { id } = useParams<ParamType>()

  const [displaysAddVoucher, setDisplaysAddVoucher] = useState(false)
  const [displayCancelOrder, setDisplayCancelOrder] = useState(false)

  const [stateType, setStateType] = useState('') //active, desactive, delete or newLine
  const [comment, setComment] = useState<string>('')
  const [checkedIdList, setCheckedIdList] = useState<string[]>([])
  const [selectedRadioAddLineName, setSelectedRadioAddLineName] =
    useState<string>('')

  const onChangeCheckedIdsListHandler = (orderLinesIds: string[]) => {
    // Si on a un seul élément dans le tableau passé en paramètres : on a cliqué sur la checkbox d'un orderline --> on va voir si on l'ajoute ou supprime du state checkedIdList
    // S'il y a plusieurs ou 0 élément --> on met à jour le state avec ce qui est envoyé (il s'agit d'un)
    if (orderLinesIds.length === 1) {
      setCheckedIdList((prevState) => {
        let newState = [...prevState]
        let orderLineIndex = newState.findIndex(
          (index) => index === orderLinesIds[0]
        )
        // Si on trouve l'order line on le retire de l'état. Si on le trouve pas, on l'ajoute
        if (orderLineIndex !== -1) {
          newState.splice(orderLineIndex, 1)
        } else {
          newState.push(orderLinesIds[0])
        }
        return newState
      })
    } else setCheckedIdList(orderLinesIds)
  }

  const resetFieldsAndSetStateType = (newStateType: string) => {
    setComment('')
    setSelectedRadioAddLineName('')
    setStateType(stateType === newStateType ? '' : newStateType)
  }

  const { loading, error, data } = useQuery(CQ_GET_ORDER, {
    variables: {
      id: +id,
    },
  })

  if (loading)
    return (
      <Card>
        <SkeletonBase count={4} variant="px-4 pt-1 mt-4" />
      </Card>
    )
  if (error) return <p>`Error! ${error.message}`</p>

  const order = data.CQgetOrder.edges[0].node.order
  const calculators = data.CQgetOrder.edges[0].node.calculators
  const isLineAddEnabled = ['created','awaiting_payment','in_verification','awaiting_preparation','in_preparation'].includes(
   order.workflowState
  )

  const canBeCancelled = ['created','awaiting_payment','in_verification','awaiting_preparation','in_preparation'].includes(
   order.workflowState
  )

  return (
    <div data-cy="orderDetail" className="custom-card py-3 mb-4">
      <div className="d-flex justify-content-between pt-3 pb-1">
        <div className="px-3 header-no-wrap">
          <HeaderTitle
            children={<ArchiveIcon className="header-title-icon" />}
            title={`${
             order.orderType === 'essdom'
                ? "Contenu de l'essDom"
                : 'Contenu de la commande'
            }`}
          />
        </div>

        <div className="d-flex pl-3 justify-content-between actions-order-line">
          {calculators.isPaid && (
            <>
              <p
                className={`custom-blue-link d-flex justify-content-between align-items-center ${
                  stateType === 'active' ? 'fw-bold' : ''
                }`}
                onClick={() => resetFieldsAndSetStateType('active')}
              >
                <CheckCircleIcon className="header-title-icon" />
                Activer les lignes cochées
              </p>
              <p
                className={`custom-blue-link d-flex justify-content-between align-items-center ${
                  stateType === 'desactive' ? 'fw-bold' : ''
                }`}
                onClick={() => resetFieldsAndSetStateType('desactive')}
              >
                <XCircleIcon className="header-title-icon primary" />
                Désactiver les lignes cochées
              </p>
            </>
          )}
          {!calculators.isPaid && (
            <p
              className={`custom-blue-link d-flex justify-content-between align-items-center ${
                stateType === 'delete' ? 'fw-bold' : ''
              }`}
              onClick={() => resetFieldsAndSetStateType('delete')}
            >
              <TrashIcon className="header-title-icon" />
              Supprimer les lignes cochées
            </p>
          )}
          <p
            className={`custom-blue-link d-flex justify-content-between align-items-center ${
              stateType === 'newLine' ? 'fw-bold' : ''
            } ${!isLineAddEnabled ? 'text-lightgray' : ''}`}
            title={
              !isLineAddEnabled
                ? 'La commande est déjà en préparation'
                : undefined
            }
            onClick={() => {
              if (isLineAddEnabled) resetFieldsAndSetStateType('newLine')
            }}
          >
            <PlusCircleIcon className="header-title-icon primary" />
            Nouvelle ligne
          </p>
        </div>
      </div>

      <div className="d-flex">
        {stateType && ['active', 'desactive'].includes(stateType) && (
          <ActivateOrDeactivateLinesForm
            stateType={stateType}
            checkedIdList={checkedIdList}
            resetStateType={resetFieldsAndSetStateType}
            comment={comment}
            setComment={setComment}
            resetCheckedIdList={onChangeCheckedIdsListHandler}
          />
        )}

        {stateType && stateType === 'delete' && (
          <DeleteLinesForm
            order={order}
            checkedIdList={checkedIdList}
            resetStateType={resetFieldsAndSetStateType}
            comment={comment}
            setComment={setComment}
            resetCheckedIdList={onChangeCheckedIdsListHandler}
          />
        )}

        {stateType && stateType === 'newLine' && (
          <AddNewLineForm
            order={order}
            resetStateType={resetFieldsAndSetStateType}
            selectedRadioAddLineName={selectedRadioAddLineName}
            setSelectedRadioAddLineName={setSelectedRadioAddLineName}
          />
        )}
      </div>

      <OrderDetailTable
        order={order}
        calculators={calculators}
        initCheckedIdList={checkedIdList}
        onChangeCheckedIdsList={onChangeCheckedIdsListHandler}
      />
      {order.orderType !== 'essdom' && (
        <Button
          className="ms-3 my-2"
          variant="outline-primary"
          onClick={() => {
            setDisplayCancelOrder(false)
            setDisplaysAddVoucher((prevValue) => !prevValue)
          }}
        >
          Ajout BDR
        </Button>
      )}
      {canBeCancelled && (
        <Button
          className="ms-3 my-2"
          variant="outline-primary"
          onClick={() => {
            setDisplaysAddVoucher(false)
            setDisplayCancelOrder((prevValue) => !prevValue)
          }}
        >
          Annuler la commande
        </Button>
      )}

      {displaysAddVoucher && (
        <>
          <VoucherSearchAndUpdate
            btnLabel="Utiliser le bon"
            confirmMessage="Souhaitez-vous utiliser le bon :"
            toastTitle={`Bon ajouté à la commande ${order.id} :`}
            customerId={order.customer.id}
            orderId={order.id}
            managesGenericVouchers={true}
          />

          <Button className="ms-3" onClick={() => setDisplaysAddVoucher(false)}>
            Retour
          </Button>
        </>
      )}

      {displayCancelOrder && (
        <CancelOrderForm setDisplayCancelOrder={setDisplayCancelOrder} orderLineList={order.orderLines}/>
      )}
    </div>
  )
}

export default OrderDetail
