import { gql } from '@apollo/client'

export const GET_DO_ACCESSORY_CUSTOM_LISTING = gql`
  query GET_DO_ACCESSORY_CUSTOM_LISTING($accessoryNameFilter: String!) {
    getDOAccessoryCustomListing(accessoryName: $accessoryNameFilter) {
      totalCount
      edges {
        node {
          id
          key
          sku
          variantLevel
          salePrice {
            value
          }
          imageUrls
          name
          visible
          color {
            ... on object_DOColor {
              label
            }
          }
          images {
            image {
              fullpath
            }
          }
          stock {
            ... on fieldcollection_DOProductStockFC {
              availableQuantity
              essdomQuantity
              bbgrQuantity
            }
          }
        }
      }
    }
  }
`
