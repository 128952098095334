import { useLazyQuery } from '@apollo/client'
import { GET_DO_ACCESSORY_CUSTOM_LISTING } from 'graphql/queries/getDOAccessoryCustomListing'
import { GET_DO_FRAME_CUSTOM_LISTING } from 'graphql/queries/getDOFrameCustomListing'
import React from 'react'
import { useState } from 'react'
import { Col, Container, Form, Nav, Row, Tab } from 'react-bootstrap'
import Skeleton from 'react-loading-skeleton'
import { constants } from 'utils/constants'
import SearchInput from '../SearchInput'
import { validateDecimalInput } from 'utils/functions'

interface Props {
  isFrame: boolean
  placeholder: string
  setSelectedProduct: React.Dispatch<React.SetStateAction<any>>
  salePrice?: string
  setSalePrice?: React.Dispatch<React.SetStateAction<string>>
}
const ProductSearchForm: React.FC<Props> = ({
  isFrame,
  placeholder,
  setSelectedProduct,
  salePrice,
  setSalePrice
}) => {
  const { REACT_APP_PIMCORE_URL_DOMAIN } = process.env

  // state for SearchInput to display or hide the result
  const [displayResult, setDisplayResult] = useState(false)
  const [hasNoResultSelected, setHasNoResultSelected] = useState(true)

  // Query is executed by SearchInput
  // frame search
  const [
    getDOFrameCustomListing,
    { loading: framesLoading, data: framesData },
  ] = useLazyQuery(GET_DO_FRAME_CUSTOM_LISTING, {
    fetchPolicy: 'network-only',
    onCompleted: () => {
      setSelectedProduct({}) // Reset selected frame to disable add button if a new search has been launched
    },
  })
  // accessory search
  const [
    getDOAccessoryCustomListing,
    { loading: accessoryLoading, data: accessoryData },
  ] = useLazyQuery(GET_DO_ACCESSORY_CUSTOM_LISTING, {
    fetchPolicy: 'network-only',
    onCompleted: () => {
      setSelectedProduct({}) // Reset selected frame to disable add button if a new search has been launched
    },
  })

  const searchFunction = (searchInputText: string) => {
    isFrame ? getDOFrameCustomListing({
      variables: {
        frameNameFilter: searchInputText.replace(/\s+/g, ' ').trim(),
      },
    })
    : getDOAccessoryCustomListing({
      variables: {
        accessoryNameFilter: searchInputText.replace(/\s+/g, ' ').trim(),
      },
    })
  }

  // create tab.pane and nav.item with the list of frames
  let jsonData: any
  if ((isFrame && framesData) || (!isFrame && accessoryData)) {
    jsonData = JSON.parse(
      JSON.stringify(isFrame ? 
        framesData?.getDOFrameCustomListing?.edges 
        : accessoryData?.getDOAccessoryCustomListing?.edges)
    )

    jsonData.tab = []
    jsonData.nav = []
    jsonData.map((item: any) => {
      let imagesUrlsT = JSON.parse(item.node.imageUrls)
      jsonData.tab.push(
        <Tab.Pane eventKey={item.node.key}>
          <Col style={{ fontSize: '12px' }} className="text-center my-3">
            {(item.node.images || imagesUrlsT?.length) && (
              <img
                src={
                  item.node.images
                    ? REACT_APP_PIMCORE_URL_DOMAIN +
                      item.node.images[0].image.fullpath
                    : imagesUrlsT.find((obj: any) => obj.order === 1).url
                }
                alt={item.node.name}
                style={{
                  height: '100%',
                  width: '140px',
                  objectFit: 'contain',
                  objectPosition: 'center',
                }}
              />
            )}
            <Col>
              <div className="d-flex align-items-center flex-column">
                <span>
                  Stock de vente : {item.node.stock?.[0].availableQuantity + item.node.stock?.[0].bbgrQuantity}
                </span>
                {isFrame && <span>
                  Stock d'essDom : {item.node.stock?.[0].essdomQuantity}
                </span>}
                <span className="fw-bold">
                  {item.node.salePrice?.value.toFixed(2)}{' '}
                  {constants.currency.EURO}
                </span>
                {!isFrame &&  
                  <Form.Group as={Row} controlId='accessoryPrice' className='mt-3 justify-content-center'>
                    <Form.Label column sm={5} className=''>Prix de vente TTC <sup>*</sup></Form.Label>
                    <Col sm={3} className='p-0'>
                      <Form.Control
                        type="text" 
                        placeholder={salePrice} 
                        value={salePrice} 
                        onChange={(e) => {
                          // setPrice(validateDecimalInput(e.target.value))
                            if(setSalePrice && (parseFloat(validateDecimalInput(e.target.value)))!==null)
                            setSalePrice((validateDecimalInput(e.target.value)))
                          }
                        }
                      />
                    </Col>
                  </Form.Group>
                } 
              </div>
            </Col>
          </Col>
        </Tab.Pane>
      )
      return jsonData.nav.push(
        <Nav.Item>
          <Nav.Link
            eventKey={item.node.key}
            onClick={() => {
              setHasNoResultSelected(false)
              isFrame?
              setSelectedProduct({
                id: item.node.id,
                productName : item.node.name,
                salePrice: null
              })
              : setSelectedProduct({
                id: item.node.id,
                productName : item.node.name,
                salePrice : item.node.salePrice.value
              })
              if(setSalePrice)
                setSalePrice(item.node.salePrice.value.toString())
              
            }}
            className={item.node.visible ? 'text-primary' : 'text-danger'}
          >{isFrame?`${item.node.name} - ${item.node.color.label} - ${item.node.sku} - ${item.node.lensWidth.value}*${item.node.bridgeWidth.value} - ${item.node.salePrice.value}€`
          :`${item.node.name} - ${item.node.color.label} - ${item.node.sku} - ${item.node.salePrice.value}€`
          }</Nav.Link>
        </Nav.Item>
      )
    })
  }

  return (
    <Container>
      <SearchInput
        placeholder={placeholder}
        searchFunction={searchFunction}
        setDisplayResult={setDisplayResult}
        setHasNoResultSelected={setHasNoResultSelected}
        setSelectedObject={setSelectedProduct}
      />

      <Row>
        {(framesLoading || accessoryLoading) && <Skeleton count={10} />}

        {((!framesLoading && framesData && isFrame) || (!isFrame && !accessoryLoading && accessoryData)) && displayResult ? (
          <Tab.Container id="" defaultActiveKey="first">
            {(isFrame? framesData.getDOFrameCustomListing.totalCount 
              : accessoryData.getDOAccessoryCustomListing.totalCount) ? (
              <>
                <Col xs={8}>
                  <div
                    className="border frame-list"
                    style={{
                      maxHeight: '340px',
                      overflowY: 'scroll',
                      padding: '0',
                    }}
                  >
                    <Nav variant="pills" className="flex-column cursor">
                      {jsonData?.nav &&
                        jsonData.nav.map((nav: any, key: number) => {
                          return (
                            <React.Fragment key={'nav-' + key}>
                              {nav}
                            </React.Fragment>
                          )
                        })}
                    </Nav>
                  </div>
                  <div className="mt-2 small">
                    <span className=" text-primary">Visible</span> {' - '}{' '}
                    <span className=" text-danger">Non visible</span>
                  </div>
                </Col>

                <Col className="d-flex flex-column align-items-center" xs={4}>
                  <Tab.Content>
                    {jsonData?.tab &&
                      jsonData.tab.map((tab: any, key: number) => {
                        return (
                          <React.Fragment key={'tab-' + key}>
                            {tab}
                          </React.Fragment>
                        )
                      })}
                  </Tab.Content>
                  {hasNoResultSelected && (isFrame ? 
                      <p> Aucune monture sélectionnée</p> : <p> Aucun accessoire sélectionné</p>)}
                </Col>
              </>
            ) : (
              <>Aucun Résultat</>
            )}
          </Tab.Container>
        ) : (
          <></>
        )}
      </Row>
    </Container>
  )
}

export default ProductSearchForm
