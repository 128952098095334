import { gql } from '@apollo/client'
import { DO_ORDER_EVENT_FIELDS } from 'graphql/fragments/DOOrderEventFields'

const CM_UPDATE_ORDER_LINE_ITEM = gql`
${DO_ORDER_EVENT_FIELDS}
mutation CM_UPDATE_ORDER_LINE_ITEM(
  $orderLineItemId: Int! , 
  $unitAmount: Float!
) {
  updateOrderLineItem(
    orderLineItemId: $orderLineItemId,
    unitAmount: $unitAmount
  ) {
    success
    output {
      orderLineItem {
      	id
        unitAmount {
        	value
        }
      }
      orderEvent {
        ...DOOrderEventFields
      }
    }
  } 
}`

export default CM_UPDATE_ORDER_LINE_ITEM