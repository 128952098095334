import HeaderTitle from './HeaderTitle'
import { ClockIcon } from '@heroicons/react/outline'
import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import ResultsTable from 'components/UI/ResultsTable'
import SkeletonBase from 'components/Skeleton/SkeletonBase'
import Card from './UI/Card'
import GET_ORDER_EVENTS from 'graphql/queries/getDOOrderEvents'
import React, { useState } from 'react'
import DOModal from 'components/Modal/DOModal'
import DOButton from 'components/Modal/DOButton'
import { stringsToJSXs } from 'utils/functions'
import { useTranslation } from 'react-i18next'
import { Button } from 'react-bootstrap'
import ModalCloseStatus from './Modal/ModalCloseStatus'
import classes from './EventsTable.module.css'

interface Props {}

const EventsTable: React.FC<Props> = () => {
  const { id } = useParams<ParamType>()
  const { t } = useTranslation()

  const columnsTitles = [
    'creation',
    'closure',
    'status',
    'comment',
    'mailInfo',
    'sentEmail',
  ]

  const [checkedIdList, setCheckedIdList] = useState<string[]>([])
  const [showConfirmCloseEventsModal, setShowConfirmCloseEventsModal] = useState(false)
  const handleCloseConfirmModal = () => setShowConfirmCloseEventsModal(false)
  const [tableLimit, setTableLimit] = useState(20)
  const { loading, error, data } = useQuery(GET_ORDER_EVENTS, {
    variables: {
      id: +id,
    },
  })
  if (loading)
    return (
      <Card>
        <SkeletonBase count={5} />
      </Card>
    )
  if (error) return <p>`Error! ${error.message}`</p>

  const events = data.CQgetOrder.edges[0].node.order.events
    .filter((event: orderEvent) => event)
    .sort(
      (eventA: eventTypeType, eventB: eventTypeType) =>
        //classe les events de la date la plus ancienne à la plus récente (avec les ids)
        +eventA.id! - +eventB.id!
    )
  // Building jsonData to display
  const jsonData = JSON.parse(JSON.stringify(events))
  jsonData.map((event: any) => {
    event.status = event.label
    event.sentEmail = event.sentEmail ? 'oui' : 'NON'
    event.creation =
      new Intl.DateTimeFormat('fr', {
        dateStyle: 'short',
        timeStyle: 'short',
      }).format(new Date(event.creationDate * 1000)) +
      (event.userLoginCreate ? ' par ' + event.userLoginCreate : '')

    event.closure = event.closureDate
      ? event.closureDate +
        (event.userLoginClosure ? ' par ' + event.userLoginClosure : '')
      : ''
    //hightlight open events  
    event.isHighlighted = !event.closureDate
    //checkbox is visible if event is open, has not workflowTransition and is etape
    event.checkboxVisible = !event.closureDate && !event.eventType.workflowTransition && event.eventType.eventStage==='etape'
    event.isLastOpen = event.eventType.isLastOpen
    return event
  })
  //list of events that can be closed with isLastOpenEvent true 
  const isLastOpenList = jsonData.filter((event:any) => event.isLastOpen && !event.closureDate);
  //boolean true if we can close events (if not every eventId of isLastOpenList are in checkedIdList)
  const isAuthorisedToClose = isLastOpenList.length ? !isLastOpenList.every((event:any) => checkedIdList.some((checkedId) => checkedId === event.id)) : true
  
  return (
    <div data-cy="eventsHistory" className={`custom-card py-3 mb-4 ${classes.container}`}>
      <div className="px-3">
        <HeaderTitle
          children={<ClockIcon className="header-title-icon" />}
          title="Historique des statuts"
        />
      </div>

      {/* bouton qui ouvre la modal pour voir tous les statuts */}
      <div className="text-center">
        <DOButton
          className={`btn-primary btn-radius ${jsonData.length<=20?'disabled':''}`}
          text={`${jsonData.length > tableLimit ? "⊕ Voir statuts précédents" : " ⊖ Voir moins"}`}
          handleClick={() => jsonData.length>20 && setTableLimit(tableLimit===20?jsonData.length:20)}
        />
      </div>
      {/* fin du bouton */}

      {/* dans ce SearchResultsTable on affiche que les tableLimit derniers statuts */}
      <ResultsTable
        queryType="Statuts"
        items={jsonData.slice(
          jsonData.length > tableLimit ? jsonData.length - tableLimit : 0,
          jsonData.length
        )}
        columns={stringsToJSXs(columnsTitles, t)}
        checkedIdListState={{ checkedIdList, setCheckedIdList }}
      />
        <DOModal
          show={showConfirmCloseEventsModal}
          customSize="modal-647"
          closeModal={handleCloseConfirmModal}
        >
          <ModalCloseStatus 
            id={+id}
            checkedIdList={checkedIdList}
            setCheckedIdList={setCheckedIdList}
            closeModal={handleCloseConfirmModal}
            authorisedToClose={isAuthorisedToClose}
          />
        </DOModal>
      <div className={`mt-2 d-flex justify-content-end px-3 ${classes.stickyButtonContainer}`}>
        <Button disabled={checkedIdList.length === 0} onClick={()=>setShowConfirmCloseEventsModal(true)}>
          Clore les statuts sélectionnés
        </Button>
      </div>
    </div>
  )
}

export default EventsTable
