import './OrderDetailCard.css'
import { DuplicateIcon } from '@heroicons/react/outline'
import React, { useContext, useEffect, useState } from 'react'
import {
  getEyewearData,
  sortOrderLinesByPrescriptions,
  sortOrderLinesByType,
} from 'utils/functions'
import Prescription from './Prescription'
import OrderDetailLeftSide from './OrderDetailLeftSide'
import TypeBadge from './TypeBadge'
import { useTranslation } from 'react-i18next'
import VisionTypeForm from './VisionTypeForm'
import DUPLICATE_ORDER_LINE from 'graphql/mutations/duplicateOrderLine'
import { useMutation } from '@apollo/client'
import { Button, Modal, Spinner, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { ToastContext } from 'store/toast-context'
import DOModal from 'components/Modal/DOModal'
import EditFrameModal from './EditFrameModal'
import EditOrderLineItemModal from './EditOrderLineItemModal'
import EditAccessoryModal from './EditAccessoryModal'
import GlobalContext from 'store/global-context'
interface Props {
  order: orderType
  onChangeCheckedIdsList: (orderLinesIds: string[]) => void
  initCheckedIdList: string[]
  calculators: orderCalculatorsType
}

const OrderDetailTable: React.FC<Props> = ({
  order,
  calculators,
  onChangeCheckedIdsList,
  initCheckedIdList,
}) => {
  const globalContext = useContext(GlobalContext)

  const [openPrescription, setOpenPrescription] = useState<null | number>(null)
  const [isCheckAll, setIsCheckAll] = useState<boolean>(false)
  const [checkedIdList, setCheckedIdList] = useState<string[]>([])
  const [showDupModal, setShowDupModal] = useState<boolean>(false)
  const [duplicatedLine, setDuplicatedLine] = useState<any>(null)
  const [editionModal, setEditionModal] = useState('')
  // state to set item and orderLine used in modals
  const [itemAndOrderLine, setItemAndOrderLine] = useState({} as any)
  const isBeneficiaryOff =
    order.orderLines.filter(
      (orderLine) => orderLine.prescription && orderLine.status === 'on'
    ).length === 0

  const handleCloseDupModal = () => setShowDupModal(false)
  const handleShowDupModal = () => setShowDupModal(true)

  const { t } = useTranslation()
  const { toast } = useContext(ToastContext)

  const [duplicateOrderLine, { loading }] = useMutation(DUPLICATE_ORDER_LINE)

  const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsCheckAll((prevIsCheckAll) => !prevIsCheckAll)

    const idList = [] as any
    order.orderLines.map((orderLine: orderLineType) => {
      return orderLine.state !== 'fac' ? idList.push(orderLine.id) : ''
    })

    setCheckedIdList(e.target.checked ? idList : [])

    onChangeCheckedIdsList(e.target.checked ? idList : [])
  }

  const handleClick = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, checked } = e.target
    if (checked) {
      setCheckedIdList((prevIdList) => [...prevIdList, id])
    } else {
      setCheckedIdList(checkedIdList.filter((item: string) => item !== id))
    }

    onChangeCheckedIdsList([id])
  }

  const handleDuplicate = async (
    newPrescription: boolean,
    duplicatedLine: any,
  ) => {
    try {
        const {
          items,
          prescription,
          state,
        } = duplicatedLine;

        let prescriptionId = +prescription?.id??null;
        let frameId = null;
        let frameQuantity = null;
        let frameAmount = null;
        let lensReplacementId = null;
        let accessoryId = null;
        let accessoryQuantity = null;
        let accessoryAmount = null;
        let beneficiaryId = +prescription?.beneficiary[0]?.id??null;
        const eyewearData = getEyewearData(items);
        let jsonLenses = '';
        let genericItem = null;
        let shippingFeeId = null;
        let voucherId = null;
        let key = null;
        let label = null;
        let quantity = null;
        let amount = null;

        items.forEach((item:any) => {
          switch (item.item[0].__typename) {
            case 'fieldcollection_DOOLItemFrameFC':
              frameId = +item.item[0]?.frame[0]?.id
              frameQuantity = +item.quantity
              frameAmount = +item?.unitAmount.value
              break
            case 'fieldcollection_DOOLItemLensReplacementFC':
              lensReplacementId = +item.item[0]?.lensReplacement[0]?.id
              frameQuantity = +item.quantity
              frameAmount = +item.unitAmount.value
              break
            case 'fieldcollection_DOOLItemAccessoryFC':
              accessoryId = +item.item[0]?.accessory[0]?.id
              accessoryQuantity = +item.quantity
              accessoryAmount = +item?.unitAmount.value
              break
            case 'fieldcollection_DOOLItemGenericFC':
              genericItem = item.item[0]?.genericItem[0]
              key = genericItem.key
              label = item.label
              quantity = +item.quantity
              amount = +item?.unitAmount.value
              break;
            case 'fieldcollection_DOOLItemLensFC':
              jsonLenses = JSON.stringify({right:eyewearData.right,left:eyewearData.left});
              break
            case 'fieldcollection_DOOLItemShippingFC':
              shippingFeeId = +item.id
              break;
            case 'fieldcollection_DOOLItemVoucherFC':
              voucherId = +item.id
            break;
          }
        })
        await duplicateOrderLine({
          variables: { 
            orderId:+order.id,
            orderLineId: +duplicatedLine.id, 
            state,
            frameId,
            frameQuantity,
            frameAmount,
            lensReplacementId,
            prescriptionId,
            beneficiaryId,
            jsonLenses,
            key,
            label,
            quantity,
            amount,
            newPrescription,
            shippingFeeId,
            voucherId,
            accessoryId,
            accessoryQuantity,  
            accessoryAmount
          },
        })
        toast({
          title: 'Modifications enregistrées :',
          message: 'Ligne dupliquée !',
        })
    } catch (e) {
      console.log(e)
    } finally {
      handleCloseDupModal()
    }
  }

  const getCosiumAmount = () => {
    return order.totalAmountHistory?.reduce((prev: any, current: any) =>
    +prev.entryDate.replace(/\D/g, '') > +current.entryDate.replace(/\D/g, '')
      ? prev
      : current
    )?.totalAmount.value ?? 0
  }

  const getOrderLineItemsAmount = (orderLine: orderLineType) => {
    return orderLine.items
      .map((item: itemType) => (item?.item?.find((innerItem: any) => innerItem.hasOwnProperty('voucher') || (innerItem.hasOwnProperty('genericItem') && innerItem.genericItem[0].isNegative)) ? -item.unitAmount.value : item.unitAmount.value) * item.quantity)
      .reduce((acc, currentValue) => acc + currentValue)
  }

  const getOrderLineTotalAmount = () =>
  {
    let totalOrderLines = order.orderLines
    .map((orderLine: orderLineType) => !calculators.isPaid || orderLine.status === 'on' ? getOrderLineItemsAmount(orderLine) : 0)
    .reduce((acc, currentValue) => acc + currentValue)
    return totalOrderLines < 0 ? 0 : totalOrderLines
  }

  useEffect(() => {
    setCheckedIdList(initCheckedIdList)
    let numberOfOrderLinesNotFac = 0
    order.orderLines.map((orderLine: orderLineType) => {
      return orderLine.state !== 'fac' ? numberOfOrderLinesNotFac++ : ''
    })
    setIsCheckAll(checkedIdList.length === numberOfOrderLinesNotFac)
  }, [setCheckedIdList, initCheckedIdList, checkedIdList, order])

  useEffect(() => {
    globalContext.setOrderKey(order.key)
    globalContext.setSiteDomainSuffix(order.siteDomain.domainSuffix)
    globalContext.setCosiumId(calculators.cosiumId)
  }, [order, calculators, globalContext])

  const orderLinesWithPrescription = sortOrderLinesByPrescriptions(
    order.orderLines
  )
  //on construit le nouveau tableau d'orderLines pour les afficher dans le bon ordre
  const newOrderLines = [
    ...orderLinesWithPrescription,
    ...sortOrderLinesByType(order.orderLines),
  ]

  const canBeDuplicated = order.orderType === 'panier' && ['awaiting_payment', 'in_verification', 'awaiting_preparation', 'in_preparation', 'completed'].includes(order.workflowState)

  const shadingLabelHandler = (label: string) => {
    const splittedString = label?.split('Dégradé')
    if(splittedString?.length === 2)
      return <>{splittedString[0]}<u><strong>Dégradé</strong></u>{splittedString[1]}</>
    return label
  }

  const table = newOrderLines.map((groupOfOrderLines: any, i: number) => {
    return (
      <tr key={i}>
        {/* empty td for prescription col */}
        <td></td>
        <td>
          <table className="w-100 p-2">
            <tbody>
              {groupOfOrderLines?.map((orderLine: orderLineType, k: number) => {
                const isGroupOff =
                  groupOfOrderLines.filter(
                    (orderLine: orderLineType) =>
                      orderLine.prescription && orderLine.status === 'on'
                  ).length === 0
                  const isOrderLineDuplicated = orderLine.status === 'on' && /duplicated|copy/i.test(orderLine.key)

                // Check si la ligne est désactivée
                let desactiveItemTextColor = ''

                if (orderLine.status === 'off') {
                  desactiveItemTextColor = 'text-lightgray'
                }

                return (
                  <tr className="hover-green" key={orderLine.id}>
                    {k === 0 &&
                      order.orderType !== 'essdom' &&
                      orderLine.prescription && (
                        <td
                          rowSpan={groupOfOrderLines.length}
                          className="bg-green align-top w-0"
                        >
                          <OrderDetailLeftSide
                            beneficiary={
                              groupOfOrderLines[0].prescription.beneficiary[0]
                            }
                            calculators={calculators}
                            groupOfOrderLines={groupOfOrderLines}
                            prescription={groupOfOrderLines[0].prescription}
                            openPrescription={openPrescription}
                            setOpenPrescription={setOpenPrescription}
                            numberOfPrescription={i + 1}
                            isGroupOff={isGroupOff}
                            isBeneficiaryOff={isBeneficiaryOff}
                          />
                        </td>
                      )}
                    {!orderLine.prescription &&
                      order.orderType !== 'essdom' && (
                        <td
                          className="bg-lightgray"
                          style={{ width: '290px' }}
                        ></td>
                      )}

                    <td className={isOrderLineDuplicated?'bg-duplication hover-green':''}>
                      {orderLine.items.map((item: any, j: number) => {
                        const getNumberOfEquipement = () => {
                          let numberOfEquipement
                          orderLinesWithPrescription.map(
                            (groupByPrescription: any) => {
                              if (
                                groupByPrescription[0].prescription.id ===
                                orderLine?.prescription.id
                              ) {
                                return (numberOfEquipement =
                                  groupByPrescription.length)
                              }
                              return ''
                            }
                          )
                          return numberOfEquipement
                        }
                        let gtin = item?.item?.[0]?.frame?.[0]?.gtin ?? item?.item?.[0]?.accessory?.[0]?.gtin
                        let orderDetailLabel = item?.label + gtin ? ` (${gtin})` : ''
                        // check if item and item[0] exist (item.item is null for Service Essdom)
                        if(item?.item?.[0]?.__typename)
                          switch (item.item[0].__typename) {
                            case 'fieldcollection_DOOLItemFrameFC':
                            case 'fieldcollection_DOOLItemAccessoryFC':
                              orderDetailLabel = item?.label + " ("+ gtin + ")"
                            break
                            case 'fieldcollection_DOOLItemVoucherFC':
                              orderDetailLabel =  `${item.item[0].voucher[0]?.voucherCode} (${item.label})`
                            break
                            default:
                              orderDetailLabel = item?.label
                        }
                        else
                          orderDetailLabel = item?.label
                        return (
                          <table className="w-100" key={item.id}>
                            <tbody>
                              <tr>
                                <td className="w-70 px-2">
                                  <TypeBadge
                                    item={item}
                                    orderLine={orderLine}
                                    setEditionModal={setEditionModal}
                                    setItemAndOrderLine={setItemAndOrderLine}
                                  />
                                  <span
                                    className={`${desactiveItemTextColor} text-break`}
                                  >
                                    {' '}{item.item && item.item[0].__typename === "fieldcollection_DOOLItemLensFC" ? 
                                      shadingLabelHandler(orderDetailLabel) : orderDetailLabel}
                                  </span>
                                  {orderLine.prescription &&
                                    item.item &&
                                    [
                                      'fieldcollection_DOOLItemFrameFC',
                                      'fieldcollection_DOOLItemLensReplacementFC',
                                    ].includes(item.item[0].__typename) && (
                                      <>
                                        <div className={desactiveItemTextColor}>
                                          {orderLine.visionType ===
                                            'non_renseigne' ||
                                          orderLine.visionType === ''
                                            ? "Cette monture n'a pas de type vision défini"
                                            : `Cette monture est pour ${t(
                                                orderLine.visionType
                                              )}`}
                                        </div>
                                        {/* set vision type */}
                                        {orderLine.status === 'on' && (
                                          <VisionTypeForm
                                            orderLine={orderLine}
                                          />
                                        )}
                                        {orderLine?.prescription
                                          ?.customerComment && (
                                          <div
                                            className={`${
                                              desactiveItemTextColor
                                                ? desactiveItemTextColor
                                                : 'text-danger'
                                            } text-break`}
                                          >
                                            <strong
                                              className={`${
                                                desactiveItemTextColor
                                                  ? desactiveItemTextColor
                                                  : 'text-danger'
                                              }`}
                                            >
                                              Commentaire client :
                                            </strong>{' '}
                                            {
                                              orderLine.prescription
                                                .customerComment
                                            }
                                          </div>
                                        )}
                                        {orderLine?.prescription
                                          ?.internalComment && (
                                          <div
                                            className={`${
                                              desactiveItemTextColor
                                                ? desactiveItemTextColor
                                                : 'text-success'
                                            } text-break`}
                                          >
                                            <strong
                                              className={`${
                                                desactiveItemTextColor
                                                  ? desactiveItemTextColor
                                                  : 'text-success'
                                              }`}
                                            >
                                              Commentaire interne :
                                            </strong>{' '}
                                            {
                                              orderLine.prescription
                                                .internalComment
                                            }
                                          </div>
                                        )}
                                      </>
                                    )}
                                </td>

                                <td
                                  className={`${desactiveItemTextColor} col-80px`}
                                >
                                  {(order.orderType === 'essdom' && item.hometrialProductAmount ? item.hometrialProductAmount.value : item.unitAmount.value)?.toFixed(2)}
                                </td>
                                <td
                                  className={`${desactiveItemTextColor} col-35px`}
                                >
                                  {item.quantity}
                                </td>
                                <td
                                  className={`${desactiveItemTextColor} col-80px`}
                                >
                                  {(
                                    item.unitAmount.value * item.quantity
                                  )?.toFixed(2)}
                                </td>
                                <td
                                  className={`${desactiveItemTextColor} col-50px`}
                                >
                                  {orderLine.state?.toUpperCase()}{' '}
                                  {/* display stockState if item is a frame or accessory */}
                                  {item.item && (item.item[0].__typename === "fieldcollection_DOOLItemFrameFC" ||
                                    item.item[0].__typename === "fieldcollection_DOOLItemAccessoryFC")
                                    && orderLine.stockState?.toUpperCase()}
                                </td>
                                {/* if canBeDuplicated show the duplicate icon only once per orderline */}
                                {canBeDuplicated && j ===0 &&<td
                                  className={`${desactiveItemTextColor} col-30px `}
                                >
                                  {
                                    loading ? 
                                    <Spinner
                                      as="span"
                                      variant="secondary"
                                      animation="border"
                                      size="sm"
                                      role="status"
                                      aria-hidden="true"
                                    /> :                       
                                    <DuplicateIcon
                                      style={{ cursor: 'pointer' }}
                                      className={`header-title-icon ${
                                        desactiveItemTextColor
                                          ? desactiveItemTextColor
                                          : 'custom-blue-link'
                                      }`}
                                      //dupliquer une ligne
                                      onClick={() => {
                                        if(!loading)
                                        if (orderLine.state === 'fac') {
                                          if (
                                            window.confirm(
                                              'êtes-vous sûr(e) de vouloir dupliquer une ligne FAC '
                                            )
                                          ) {
                                            handleDuplicate(true, orderLine)
                                          }
                                        } else if (!orderLine.prescription) {
                                          handleDuplicate(false, orderLine)
                                        } else {
                                          handleShowDupModal()
                                          setDuplicatedLine(orderLine)
                                        }
                                      }}
                                    />
                                  }
                                </td>}
                                <td
                                  className={`${desactiveItemTextColor} col-30px px-2`}
                                >
                                  {item.item &&
                                  !['fieldcollection_DOOLItemLensFC'].includes(
                                    item.item[0].__typename
                                  ) ? (
                                    orderLine.state !== 'fac' && (
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id={orderLine.id.toString()}
                                        checked={
                                          checkedIdList &&
                                          checkedIdList.includes(
                                            orderLine.id.toString()
                                          )
                                        }
                                        onChange={handleClick}
                                      />
                                    )
                                  ) : (
                                    <div style={{ width: '16px' }}></div>
                                  )}
                                </td>
                              </tr>
                              {orderLine.prescription &&
                                openPrescription ===
                                  +orderLine.prescription.id &&
                                // display prescription after last item
                                (j + 1) % orderLine.items.length === 0 &&
                                // display prescription after last orderline
                                getNumberOfEquipement() === k + 1 && (
                                  <tr className="position-relative bg-white">
                                    <td colSpan={7}>
                                      <Prescription
                                        isAlertOpen={false}
                                        beneficiary={
                                          orderLine.prescription.beneficiary[0]
                                        }
                                        prescription={orderLine.prescription}
                                        numberOfPrescription={i + 1}
                                        orderLines={groupOfOrderLines}
                                        order={order}
                                        isGroupOff={isGroupOff}
                                      />
                                    </td>
                                  </tr>
                                )}
                            </tbody>
                          </table>
                        )
                      })}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </td>
      </tr>
    )
  })

  return (
    <div className="border border-0 border-top border-bottom pb-2 mb-2 ">
      <table className="sticky-header w-100">
        <thead>
          <tr>
            {/* empty th for prescription col */}
            <th> </th>
            <th
              style={{
                position: 'sticky',
                top: '80px',
              }}
            >
              <table className="w-100">
                <thead>
                  <tr>
                    {order.orderType !== 'essdom' && (
                      <th
                        className="bg-transparent"
                        style={{ width: '290px' }}
                      ></th>
                    )}
                    <th>
                      {' '}
                      <table className="w-100">
                        <thead className="bg-white">
                          <tr>
                            <th className="w-70 px-2">Descriptif</th>
                            <th className="col-80px">P.U.</th>
                            <th className="col-30px">Qté.</th>
                            <th className="col-80px">Total</th>
                            <th className="col-50px">Statut</th>
                            {canBeDuplicated && <th className="col-30px text-center">x2</th>}

                            <th className="col-30px px-2">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                checked={isCheckAll}
                                onChange={handleSelectAll}
                              />
                            </th>
                          </tr>
                        </thead>
                      </table>
                    </th>
                  </tr>
                </thead>
              </table>
            </th>
          </tr>
        </thead>
        <tbody>
          {table}
          <tr>
            <td></td>
            <td>
              <table className="w-100 p-2">
                <tbody>
                  <tr className="hover-green">
                    <td style={{ width: '290px' }}></td>
                    <td>
                      <table className="w-100">
                        <tbody>
                          <tr className="mt-2">
                            <td className="w-70 px-2"></td>
                            <td className="col-115px d-flex justify-content-end pe-2"><span className={!calculators.isPaid ? "text-lightgray" : ""}><b>Total lignes : </b></span></td>
                            <td className="col-80px"><span className={!calculators.isPaid ? "text-lightgray" : ""}><b>{getOrderLineTotalAmount()?.toFixed(2)}</b></span></td>
                            <td className="col-50px pt-1 d-flex justify-content-end pe-2" style={{ fontSize: '14px' }}>
                              <span className={getOrderLineTotalAmount() - getCosiumAmount() ? "text-warning" : "text-success"}><b>Diff : </b></span>
                            </td>
                            <td className="col-62px pt-1" style={{ fontSize: '14px' }}>
                              <OverlayTrigger
                                  placement="bottom"
                                  overlay={
                                      <Tooltip id="tooltip-disabled">
                                        Différence du "Total lignes" avec le montant Cosium (= {getCosiumAmount().toFixed(2)})
                                      </Tooltip>
                                  }
                              >
                                <span className={getOrderLineTotalAmount() - getCosiumAmount() ? "text-warning" : "text-success"}>
                                  <b>{(getOrderLineTotalAmount() - getCosiumAmount()).toFixed(2)}</b>
                                </span>
                              </OverlayTrigger>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
      {editionModal && (
        <DOModal
          show={!!editionModal}
          closeModal={() => setEditionModal('')}
          size="lg"
        >
          {editionModal === 'frame' ? (
            <EditFrameModal
              item={itemAndOrderLine.item}
              orderLine={itemAndOrderLine.orderLine}
              calculators={calculators}
              isHometrial={order.orderType === 'essdom'}
            />
          ) : editionModal === 'accessory'?
            <EditAccessoryModal
              item={itemAndOrderLine.item}
              orderLine={itemAndOrderLine.orderLine}
            />
          :(
            <EditOrderLineItemModal
              orderLineId={+itemAndOrderLine.orderLine?.id}
              item={itemAndOrderLine.item}
            />
          )}
        </DOModal>
      )}

      <Modal show={showDupModal} onHide={handleCloseDupModal}>
        <Modal.Header closeButton>
          <Modal.Title>Duplication de ligne {duplicatedLine?.id}</Modal.Title>
        </Modal.Header>
        <Modal.Body>Voulez-vous utiliser la même ordonnance ?</Modal.Body>
        <Modal.Footer>
          {loading && (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          )}

          {!loading && (
            <>
              <Button
                variant="secondary"
                onClick={() => handleDuplicate(false, duplicatedLine)}
              >
                Oui
              </Button>
              <Button
                variant="primary"
                onClick={() => handleDuplicate(true, duplicatedLine)}
              >
                Non
              </Button>
            </>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default OrderDetailTable
