import { gql } from '@apollo/client'

export const REPLACE_PRODUCT = gql`
  mutation ($orderLineItemId: Int!, $newProductId: Int!, $salePrice: Float) {
    replaceProduct(
      orderLineItemId: $orderLineItemId
      newProductId: $newProductId
      salePrice: $salePrice
    ) {
      success
      output {
        cmFailComment
        order {
          events {
            ... on object_DOOrderEvent {
              id
              eventType {
                ... on object_DOOrderEventType {
                  id
                  key
                  eventFamily
                }
              }
            }
          }
        }
        orderLineItem {
          id
          label
          unitAmount {
            value
          }
          item {
            ... on fieldcollection_DOOLItemAccessoryFC {
              accessory {
                ... on object_DOAccessory{
                  id
                  name
                  salePrice {
                    value
                  }
                }
              }
            }
            ... on fieldcollection_DOOLItemFrameFC {
              frame {
                ... on object_DOFrame {
                  id
                  name
                  salePrice {
                    value
                  }
                }
              }
            }
          }
          orderLine {
            ... on object_DOOrderLine {
              id
              stockState
            }
          }
        }
        toPutPhysicallyBackToStock
      }
    }
  }
`
